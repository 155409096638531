@import 'lexical';
@import 'signature';

.org-card {
  height: 65px;

  .org-description {
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.chat-page {
  .card#kt_chat_messenger {
    height: 100%;
    text-align: center;
    justify-content: center;

    .card-header {
      padding: 0 1rem;
    }

    .card-body {
      padding: 1rem;
      padding-right: 0.25rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .card-footer {
      padding: 0;
      padding-right: 0.5rem;

      .form-control {
        padding: 0.5rem;
      }
    }
  }

  .card {
    &.card-conv-list {
      .card-header {
        padding: 1rem;
      }

      .card-body {
        padding: 0;
        padding-right: 1.25rem;

        .scroll-y {
          padding-right: 0.75rem;
          margin-right: -0.75rem;
        }

        .conversation-wrapper {
          padding-left: 0.75rem;

          .conversation-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.75rem 0.5rem;

            &.active {
              background: rgba(0, 0, 0, 0.1);
            }

            .conv-info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: calc(100% - 50px);
            }
          }
        }
      }
    }
  }

  .chat-body-container {
    display: flex;
    flex-direction: column-reverse;
  }
}

.call-page {
  padding: 15px;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 1;

  .call-layout-wrapper {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    flex: 1;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.3);

    > div {
      position: relative;
      vertical-align: middle;
      align-self: center;
      border-radius: 10px;
      overflow: hidden;
      display: inline-block;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1),
        0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1),
        0 32px 64px rgba(0, 0, 0, 0.1);
      background: #fff;
      animation: show 0.4s ease;
    }
  }
}

.object-fit-cover {
  object-fit: cover;
}

@media screen and (min-width: 767px) {
  .card-body#kt_chat_contacts_body {
    height: calc(100vh - 180px);

    &.staff-chat {
      height: calc(100vh - 230px);
    }
  }
}

@media screen and (max-width: 767px) {
  .card-body#kt_chat_contacts_body {
    height: calc(100vh - 100px);
  }

  .chat-page {
    .card#kt_chat_messenger {
      height: calc(100vh - 100px);
    }
  }
}

@media screen and (min-width: 992px) {
  .card-body#kt_chat_contacts_body {
    height: calc(100vh - 220px);

    &.staff-chat {
      height: calc(100vh - 270px);
    }
  }

  .chat-page {
    .card#kt_chat_messenger {
      .card-body {
        height: calc(100vh - 220px);
      }
    }
  }
}

.account-page {
  .nav-link {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;

    &.active {
      color: var(--bs-link-color);
    }
  }

  .profile-page {
    .img-account-profile {
      height: 10rem;
      width: 10rem;
    }

    .card {
      box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
    }

    .card .card-header {
      font-weight: 500;
      font-size: 20px;
      font-weight: 28px;
    }

    .card-header:first-child {
      border-radius: 0.35rem 0.35rem 0 0;
    }

    .card-header {
      padding: 1rem 1.35rem;
      margin-bottom: 0;
      background-color: rgba(33, 40, 50, 0.03);
      border-bottom: 1px solid rgba(33, 40, 50, 0.125);
    }

    .nav-borders .nav-link.active {
      color: #0061f2;
      border-bottom-color: #0061f2;
    }

    .nav-borders .nav-link {
      color: #69707a;
      border-bottom-width: 0.125rem;
      border-bottom-style: solid;
      border-bottom-color: transparent;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0;
      padding-right: 0;
      margin-left: 1rem;
      margin-right: 1rem;
    }

    .btn-danger-soft {
      color: #000;
      background-color: #f1e0e3;
      border-color: #f1e0e3;
    }
  }

  .security-page {
    background-color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;

    .title {
      font-weight: 500;
      font-size: 20px;
      font-weight: 28px;
    }
  }
}

.note-modal {
  .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 210px);
    display: flex;
    justify-content: center;

    .page {
      width: 8.5in;
      height: calc(11in + 40px);
      border: 1px solid gray;
      padding: 0.75in;
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      font-size: 15px;
    }
  }
}

.pdf-edit-modal {
  .modal-body {
    overflow-y: auto;
    max-height: calc(100vh - 210px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.pdf-document {
  width: max-content;

  .pdf-page {
    border: 1px solid gray;
    margin-bottom: 1rem;
  }
}

.pdf-editor-navbar {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px #000;

  * {
    border-color: #000;
  }

  span {
    padding: 0.5rem;
  }

  .pdf-editor-label {
    display: flex;
    align-items: center;

    > span {
      cursor: pointer;
      border-left: 1px solid;
      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}

.pdf-editor-main-content {
  padding-top: 0.5rem !important;

  #canvasWrapper {
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .pdf-page {
    border: 1px solid #000;
  }

  .change-page-button {
    border-radius: 4px;
    background: white;
    padding: 5px 10px;

    &.opacity-0 {
      cursor: default;
    }
  }
}

.selection-card {
  cursor: pointer;
  box-shadow: 0 0 1px 1px #dedede;

  .card-header {
    background: rgba(0, 0, 0, 0.03);
  }

  &.active {
    box-shadow: 0 0 1px 1px #2ecc71;
  }
}

.loading-filter-wrapped {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(204, 204, 204, 0.3);

  .loading-filter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.min-w-200 {
  min-width: 200px;
}

.react-tags {
  position: relative;
  padding: 0.25rem 0 0 0.25rem;
  border: 2px solid #afb8c1;
  border-radius: 6px;
  background: #ffffff;
  font-size: 1rem;
  line-height: 1.2;
  cursor: text;
}
.react-tags.is-active {
  border-color: #4f46e5;
}
.react-tags.is-disabled {
  opacity: 0.75;
  background-color: #eaeef2;
  pointer-events: none;
  cursor: not-allowed;
}
.react-tags.is-invalid {
  border-color: #fd5956;
  box-shadow: 0 0 0 2px rgba(253, 86, 83, 0.25);
}
.react-tags__label {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.react-tags__list {
  display: inline;
  padding: 0;
}
.react-tags__list-item {
  display: inline;
  list-style: none;
}
.react-tags__tag {
  margin: 0 0.25rem 0.25rem 0;
  padding: 0.375rem 0.5rem;
  border: 0;
  border-radius: 3px;
  background: #eaeef2;
  font-size: inherit;
  line-height: inherit;
  &:hover {
    color: #ffffff;
    background-color: #4f46e5;
    &::after {
      background-color: #ffffff;
    }
  }
  &::after {
    content: '';
    display: inline-block;
    width: 0.65rem;
    height: 0.65rem;
    clip-path: polygon(
      10% 0,
      0 10%,
      40% 50%,
      0 90%,
      10% 100%,
      50% 60%,
      90% 100%,
      100% 90%,
      60% 50%,
      100% 10%,
      90% 0,
      50% 40%
    );
    margin-left: 0.5rem;
    font-size: 0.875rem;
    background-color: #7c7d86;
  }
}
.react-tags__combobox {
  display: inline-block;
  padding: 0.375rem 0.25rem;
  margin-bottom: 0.25rem;
  max-width: 100%;
}
.react-tags__combobox-input {
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: none;
  font-size: inherit;
  line-height: inherit;
  &::placeholder {
    color: #7c7d86;
    opacity: 1;
  }
}
.react-tags__listbox {
  position: absolute;
  z-index: 1;
  top: calc(100% + 5px);
  left: -2px;
  right: -2px;
  max-height: 12.5rem;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #afb8c1;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -4px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px;
}
.react-tags__listbox-option {
  padding: 0.375rem 0.5rem;
  &:hover {
    cursor: pointer;
    background: #eaeef2;
  }
  &:not([aria-disabled='true']).is-active {
    background: #4f46e5;
    color: #ffffff;
  }
}
.react-tags__listbox-option[aria-disabled='true'] {
  color: #7c7d86;
  cursor: not-allowed;
  pointer-events: none;
}
.react-tags__listbox-option[aria-selected='true'] {
  &::after {
    content: '✓';
    margin-left: 0.5rem;
  }
  &:not(.is-active) {
    &::after {
      color: #4f46e5;
    }
  }
}
.react-tags__listbox-option-highlight {
  background-color: #ffdd00;
}

.badge-close-wrapper {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  width: 10px;
  height: 10px;
  right: -5px;
  top: -5px;
  background: red;
  border-radius: 50%;

  .badge-close {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 2px;
      background: white;
      right: -9px;
      top: 4px;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
}

.z-20 {
  z-index: 20;
}

.select-organization {
  height: max-content;
}

.w-200-imp {
  width: 200px !important;
}
