.dropdown-lexical {
  z-index: 9999;
  display: block;
  position: absolute;
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 80px;
  min-height: 40px;
  background-color: #fff;
  .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    &:first-child {
      margin-top: 8px;
    }
    &:last-child {
      margin-bottom: 8px;
    }
    &:hover {
      background-color: #eee;
    }
    .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      width: 150px;
    }
    .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
      color: #000;
      font-size: 18px;
    }
  }
}

.lexical-editor {
  margin: 0px -10px;
  font-size: 15px;
  flex: 1;

  i {
    color: #000;
    font-size: 18px;
  }
  h1 {
    font-size: 24px;
    color: #333;
  }
  .ltr {
    text-align: left;
  }
  .rtl {
    text-align: right;
  }
  .editor-container {
    border: 1px solid #f1e0e3;
    color: #000;
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .editor-inner {
    background: #fff;
    position: relative;
    flex: 1;
  }
  .editor-input {
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    // max-height: calc(9.5rem - 140px - 70px - 40px);
    // overflow: hidden;
    caret-color: #444;
  }
  .editor-placeholder {
    color: #999;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 15px;
    left: 10px;
    font-size: 15px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
  }
  .editor-text-bold {
    font-weight: bold;
  }
  .editor-text-italic {
    font-style: italic;
  }
  .editor-text-underline {
    text-decoration: underline;
  }
  .editor-text-strikethrough {
    text-decoration: line-through;
  }
  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }
  .editor-paragraph {
    margin: 0;
    margin-bottom: 8px;
    position: relative;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }
  .editor-heading-h2 {
    font-size: 16px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
  }
  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }
  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }
  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }
  .editor-nested-listitem {
    list-style-type: none;
  }
  pre {
    &::-webkit-scrollbar {
      background: transparent;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
    }
  }
  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
  }
  .debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
  }
  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  .emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
  }
  .emoji-inner {
    padding: 0 0.15em;
    &::selection {
      color: transparent;
      background-color: rgba(150, 150, 150, 0.4);
    }
    &::moz-selection {
      color: transparent;
      background-color: rgba(150, 150, 150, 0.4);
    }
  }
  .toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    border-bottom: 1px solid #f1e0e3;
    vertical-align: middle;
    height: 37px;

    button {
      &.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        cursor: pointer;
        vertical-align: middle;
        &:disabled {
          cursor: not-allowed;
          i {
            &.format {
              opacity: 0.2;
            }
          }
        }
        &.spaced {
          margin-right: 2px;
        }
        i {
          &.format {
            background-size: contain;
            display: inline-block;
            height: 18px;
            width: 18px;
            margin-top: 2px;
            vertical-align: -0.25em;
            display: flex;
            opacity: 0.6;
          }
        }
        &.active {
          background-color: rgba(223, 232, 250, 0.3);
          i {
            opacity: 1;
          }
        }
      }
    }
    .toolbar-item {
      &:hover {
        &:not([disabled]) {
          background-color: #eee;
        }
      }
      .text {
        display: flex;
        line-height: 20px;
        vertical-align: middle;
        font-size: 14px;
        color: #777;
        text-overflow: ellipsis;
        width: 75px;
        overflow: hidden;
        height: 20px;
        text-align: left;
      }
      .icon {
        display: flex;
        width: 20px;
        height: 20px;
        user-select: none;
        margin-right: 8px;
        line-height: 16px;
        background-size: contain;
      }
    }
    .divider {
      width: 1px;
      background-color: #eee;
      margin: 0 4px;
    }
    select {
      &.toolbar-item {
        border: 0;
        display: flex;
        background: none;
        border-radius: 10px;
        padding: 8px;
        vertical-align: middle;
        width: 70px;
        font-size: 14px;
        color: #777;
        text-overflow: ellipsis;
      }
      &.code-language {
        text-transform: capitalize;
        width: 130px;
      }
    }
    i {
      &.chevron-down {
        margin-top: 3px;
        width: 16px;
        height: 16px;
        display: flex;
        user-select: none;
        &.inside {
          width: 16px;
          height: 16px;
          display: flex;
          margin-left: -25px;
          margin-top: 11px;
          margin-right: 10px;
          pointer-events: none;
        }
      }
    }
  }
  i {
    &.chevron-down {
      background-color: transparent;
      background-size: contain;
      display: inline-block;
      height: 8px;
      width: 8px;
    }
  }
  #block-controls {
    button {
      &:hover {
        background-color: #efefef;
      }
      &:focus-visible {
        border-color: blue;
      }
    }
    span {
      &.block-type {
        background-size: contain;
        display: block;
        width: 18px;
        height: 18px;
        margin: 2px;
      }
    }
  }

  .link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
    .link-input {
      display: block;
      width: calc(100% - 24px);
      box-sizing: border-box;
      margin: 8px 12px;
      padding: 8px 12px;
      border-radius: 15px;
      background-color: #eee;
      font-size: 15px;
      color: rgb(5, 5, 5);
      border: 0;
      outline: 0;
      position: relative;
      font-family: inherit;
      a {
        color: rgb(33, 111, 219);
        text-decoration: none;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 30px;
        text-overflow: ellipsis;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    div {
      &.link-edit {
        background-size: 16px;
        background-position: center;
        background-repeat: no-repeat;
        width: 35px;
        vertical-align: -0.25em;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        cursor: pointer;
      }
    }
    .button {
      width: 20px;
      height: 20px;
      display: inline-block;
      padding: 6px;
      border-radius: 8px;
      cursor: pointer;
      margin: 0 2px;
      &.hovered {
        width: 20px;
        height: 20px;
        display: inline-block;
        background-color: #eee;
      }
    }
  }
  .link-editor .button i,
  .actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }
}
