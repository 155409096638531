.sig-wrapper {
  background-color: gray;
  user-select: none;
  width: 100%;
  min-height: 500px;

  .sig-container {
    width: 70%;
    margin: 0 auto;
    background-color: white;
  }

  .sig-btn {
    width: 100%;
    height: 30px;
  }

  .sig-image {
    background-size: 200px 50px;
    width: 200px;
    height: 50px;
    background-color: white;
  }
}
